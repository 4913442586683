<template>
  <div class="my_order">
    <van-search
      v-model="search"
      shape="round"
      placeholder="搜索我的订单"
      :clearable="false"
    >
      <template #left-icon>
        <svg-icon icon-class="home_nav_search_icon" />
      </template>
      <template #left>
        <svg-icon
          icon-class="navbar_dcs_back"
          style="margin-right: 10px"
          @click="
            $route.name === 'integral-order'
              ? $router.replace('/pointsMall')
              : $router.push('/home/mine')
          "
        />
      </template>
      <template #right-icon>
        <button @click="search_btn">搜索</button>
      </template>
    </van-search>
    <van-tabs
      v-model="active"
      color="#009A4D"
      :swipeable="true"
      @change="switchTabs"
      :duration="0.5"
    >
      <van-tab
        :title="list.title"
        :name="list.i"
        v-for="(list, index) in state"
        :key="index"
      >
        <!-- <van-pull-refresh
          v-model="isLoading"
          @refresh="onRefresh"
          success-text="刷新成功"
        > -->
        <!-- v-if="orderList.length" -->
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="orderList.length ? '没有更多了' : ''"
          @load="onLoad"
          error-text="请求失败，点击重新加载"
          :offset="20"
        >
          <van-empty
            description="订单为空"
            v-if="!orderList.length && !loading" />
          <div class="goods_box" v-for="(list, i) in orderList" :key="i" v-else>
            <div class="goods_list">
              <div class="store">
                <svg-icon
                  icon-class="store_icon"
                  class="svg_store"
                  v-if="!list.unitPrice"
                />
                <b v-if="!list.unitPrice">{{ list.storeName }}</b>
                <span v-else>订单号：{{ list.dealNumber }}</span>
                <!-- <svg-icon icon-class="home_more_icon" class="svg_more" /> -->
                <p class="dealStatus0" v-if="list.dealStatus == 0">待付款</p>
                <p class="dealStatus7" v-else-if="list.dealStatus == 7">
                  交易关闭
                </p>
                <p
                  class="dealStatus3"
                  :style="list.unitPrice ? 'color: #fb4e10' : ''"
                  v-else-if="list.dealStatus == 8"
                >
                  待收货
                </p>
                <p class="dealStatus3" v-else-if="list.dealStatus == 2">
                  待发货
                </p>
                <p class="dealStatus3" v-else-if="list.dealStatus == 3">
                  待自提
                </p>
                <p
                  class="dealStatus4"
                  :style="list.unitPrice ? 'color: #fb4e10' : ''"
                  v-else-if="list.dealStatus == 4"
                >
                  已收货
                </p>
                <p class="dealStatus4" v-else-if="list.dealStatus == 9">
                  已退款
                </p>
              </div>
              <!-- :ref="`box${item}`" -->
              <div
                class="box"
                :style="
                  list.isMore
                    ? `max-height:${
                        list.orderCommodityListItems.length * 124
                      }px`
                    : 'max-height:2.48rem'
                "
                @click="goDetails(list)"
              >
                <!-- 积分商城订单 -->
                <div class="goods" v-if="list.unitPrice">
                  <img alt="" v-lazy="list.filePath" />
                  <div class="details">
                    <h4>
                      {{ list.commodityName }}
                    </h4>
                    <span v-if="list.attributeName" class="attribute">{{
                      list.attributeName
                    }}</span>
                    <p>
                      商品规格：<span>{{ list.specsParameter }}</span>
                    </p>
                    <div class="price">
                      <span>×{{ list.totalQuantity }}</span>
                      <div class="integralPrice">
                        {{ list.unitPrice }}
                        <span>积分</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 普通订单 -->
                <div
                  class="goods"
                  v-for="(item, i) in list.orderCommodityListItems"
                  :key="i"
                  v-else
                >
                  <img alt="" v-lazy="item.filePath" />
                  <div class="details">
                    <h4>
                      <span v-if="item.type === 1">[新人尝鲜价] </span
                      >{{ item.commodityName }}
                    </h4>
                    <!-- <p>
                      归属品牌：<span>{{ item.brandName }}</span>
                    </p>  -->
                    <p>
                      商品规格：<span>{{ item.specsParameter }}</span>
                    </p>
                    <span v-if="item.attributeName" class="attribute">{{
                      item.attributeName
                    }}</span>
                    <div class="price">
                      <span>×{{ item.quantity }}</span>
                      <div>
                        <span
                          >单价:<span>￥</span
                          >{{
                            (item.sellingPrice / item.quantity) | Price
                          }}</span
                        >
                        <span style="margin-left: 8px"
                          >实付:<span>￥</span> {{ item.actualPayment | Price }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="message van-hairline--top">
                <div v-if="!list.unitPrice">
                  <p class="order_num">
                    订单号：<span>{{ list.dealNumber }}</span>
                  </p>
                  <p class="time">{{ list.tradeTime | date }}</p>
                </div>
                <h6 v-if="list.dealStatus == 0">
                  需付款：￥{{ list.totalAmount2 | Price }}
                </h6>
                <h6 v-else-if="list.unitPrice" class="integralPrice">
                  积分支付：
                  <p>{{ list.unitPrice }}</p>
                  <span>积分</span>
                </h6>
                <h6 v-else-if="list.dealStatus !== 7">
                  已付款：￥{{ list.totalAmount2 | Price }}
                </h6>
                <div class="button">
                  <van-button
                    plain
                    round
                    color="#009a4d"
                    class="Btn"
                    v-if="list.logisticsInfo"
                    @click="goLogistics(list)"
                    >查看物流</van-button
                  >
                  <van-button
                    plain
                    round
                    v-if="list.dealStatus == 0"
                    @click="cancellationOrder(list)"
                    >取消订单</van-button
                  ><van-button
                    plain
                    round
                    color="#009a4d"
                    v-if="list.dealStatus == 0"
                    class="Btn"
                    @click="goPay(list.dealNumber)"
                    >继续支付</van-button
                  ><van-button
                    plain
                    round
                    v-if="list.dealStatus == 7 || list.dealStatus == 5"
                    @click="deleteOrder(list.dealNumber)"
                    >删除订单</van-button
                  >
                  <!-- @click="deleteOrder(list.totalDealNumber)" -->
                  <!-- <van-button plain round v-if="list.dealStatus == 4"
                    >申请退货</van-button
                  > -->
                  <van-button
                    plain
                    round
                    v-if="list.dealStatus == 3 || list.dealStatus == 8"
                    @click="confirmOrder(list.dealNumber)"
                    >确认收货</van-button
                  >

                  <!-- goPay(list.totalDealNumber) -->
                  <!-- <van-button plain round>申请退货</van-button>
                <van-button plain round>查看保单</van-button>
                --></div>
              </div>
              <div
                class="more"
                v-if="
                  !list.unitPrice && list.orderCommodityListItems.length > 2
                "
                @click="list.isMore = !list.isMore"
              >
                <!-- @click="moreShow(`box${item}`)" -->
                <span
                  >共{{ list.orderCommodityListItems.length }}件商品
                  <svg-icon
                    icon-class="home_more_icon"
                    class="svg_more"
                    :style="
                      list.isMore
                        ? 'transform: rotate(-90deg);'
                        : 'transform: rotate(0);'
                    "
                /></span>
              </div>
            </div></div
        ></van-list>
        <!-- </van-pull-refresh> -->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from "@/utils/validate";
import { Dialog } from "vant";
import { getOpenId, removeOpenId } from "@/utils/auth";

export default {
  name: "my-order",
  data() {
    return {
      moreIsShow: false,
      search: "",
      isLoading: false,
      active: "",
      state: [],
      orderList: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      refresh: false,
      pageNum: 1,
      // scrollTop: 0,
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.i) {
          this.active = Number(val.query.i);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  filters: {
    Price(num) {
      if (num === null) {
        return "-";
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        return num.toFixed(2);
      } else {
        return num;
      }
    },
    date(val) {
      return formatDate(val);
    },
  },
  computed: {
    ...mapState("user", [
      "isLogin",
      "userInfo",
      "quantity",
      "allIntegral",
      "bank",
    ]),
  },
  created() {
    if (this.$route.name == "my-order") {
      this.state = [
        {
          title: "全部订单",
          orderList: [],
          i: "",
        },
        {
          title: "待付款",
          amount: 0,
          orderList: [],
          i: 0,
        },
        // {
        //   title: "待发货",
        //   amount: 0,
        //   orderList: [],
        //   i: 2,
        // },
        {
          title: "待自提",
          amount: 0,
          orderList: [],
          i: 3,
        },
        {
          title: "待收货",
          amount: 0,
          orderList: [],
          i: 8,
        },
        {
          title: "已收货",
          amount: 0,
          orderList: [],
          i: 4,
        },
        {
          title: "交易关闭",
          amount: 0,
          orderList: [],
          i: 7,
        },
      ];
    } else {
      this.state = [
        {
          title: "全部订单",
          orderList: [],
          i: "",
        },
        {
          title: "待自提",
          amount: 0,
          orderList: [],
          i: 3,
        },
        {
          title: "待收货",
          amount: 0,
          orderList: [],
          i: 8,
        },
        {
          title: "已收货",
          amount: 0,
          orderList: [],
          i: 4,
        },
        // {
        //   title: "交易关闭",
        //   amount: 0,
        //   orderList: [],
        //   i: 7,
        // },
      ];
    }
  },

  //缓存
  // activated() {
  //   window.addEventListener("scroll", this.windowScroll, true);
  //   document.getElementsByClassName("van-tabs__content")[0].scrollTop =
  //     this.scrollTop;
  // },
  // deactivated() {
  //   window.removeEventListener("scroll", this.windowScroll, true);
  // },
  // beforeRouteEnter(to, from, next) {
  //   if (from.name === "mine") {
  //     next((vm) => {
  //       vm.active = "";
  //       vm.scrollTop = 0;
  //       vm.search = "";
  //       vm.pageNum = 1;
  //       vm.orderList = [];
  //       vm.finished = false;
  //       vm.loading = true;
  //       vm.onLoad();
  //     });
  //   }
  //   next();
  // },
  methods: {
    //缓存
    // windowScroll() {
    //   this.scrollTop =
    //     document.getElementsByClassName("van-tabs__content")[0].scrollTop;
    // },

    switchTabs(name, title) {
      this.search = "";
      this.refresh = true;
      this.onLoad();
    },
    goDetails(order) {
      // return
      if (this.$route.name == "my-order") {
        this.$router.push({
          name: "order-details",
          query: {
            dealNumber: order.dealNumber,
            totalDealNumber: order.totalDealNumber,
          },
        });
      } else {
        this.$router.push({
          name: "order-details",
          query: {
            integralDealNumber: order.dealNumber,
          },
        });
      }
    },
    goPay(val) {
      if (getOpenId() == "undefined" || getOpenId() == "null") removeOpenId();
      this.$router.push({
        path: "confirmAnOrder/payForTheOrder",
        query: {
          // totalDealNumber: val,
          dealNumber: val,
        },
      });
    },
    async onLoad() {
      // 异步更新数据
      if (this.refresh) {
        this.pageNum = 1;
        this.orderList = [];
        this.finished = false;
        this.loading = true;
        this.refresh = false;
      }
      if (this.$route.name == "my-order") {
        await this.$API.order
          .ClosedOrderList({
            object: {
              commodityName: this.search,
              dealStatus: this.active, //交易状态: 0-待付款，1-待确认、2-待发货，3-待自提，4-已收货，5-已完成，6-待收货，7-交易关闭，8-待采购
              keyWord: "", //关键词
              orderByAccount: "", //销售金额排序:desc/asc
              orderByTime: "desc", //销售时间排序:desc/asc
              storeName: "", //店铺名称
              tradeEndTime: "", //下单结束时间
              tradeStartTime: "", //下单起始时间
              memberUuid: this.userInfo.id, //memberUuid
            },
            pageNum: this.pageNum,
            pageSize: 6,
          })
          .then((res) => {
            // ?x-oss-process=image/resize,limit_0,m_fill,w_200,h_200/
            if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
            this.pages = res.data.pages;
            res.data.data.forEach((order) => {
              this.$set(order, "isMore", false);
              this.$set(order, "totalAmount2", 0);
              this.$set(order, "logisticsInfo", false);
              order.orderCommodityListItems.forEach((goods) => {
                if (goods.attributeName)
                  goods.attributeName = goods.attributeName
                    .split(",")
                    .join("/");
                goods.filePath = goods.filePath.split(",")[0].endsWith(".mp4")
                  ? goods.filePath.split(",")[1]
                  : goods.filePath.split(",")[0];
                if (goods.actualPayment == null) {
                  order.totalAmount2 += goods.quantity * goods.sellingPrice;
                } else {
                  order.totalAmount2 += goods.actualPayment;
                }
                if (goods.waybillNumber) order.logisticsInfo = true;
              });
              this.orderList.push(order);
            });
            this.pageNum++;
            this.loading = false;
            this.isLoading = false;
            // 加载状态结束
            if (this.orderList.length == 0) {
              this.finished = true;
            }
            // 数据全部加载完成
            if (this.pageNum > this.pages) {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.error = true;
            this.loading = false;
            this.isLoading = false;
          });
      } else {
        await this.$API.order
          .IntegralOrderList({
            object: {
              barcode: "", //条形码
              commodityName: this.search, //商品名称
              consignee: "", //收货联系人

              dealNumber: "", //订单编号
              dealStatus: this.active, //交易状态: 0-待付款，1-待确认、2-待发货，3-待自提，4-已收货，5-已完成，6-待收货，7-交易关闭，8-待采购
              freeShipping: "",
              getGoodsName: "", //会员店铺名称
              memberTelNumber: "", //团员手机号
              order: "desc",
              telephone: "", //收货人联系电话
            },
            pageNum: this.pageNum,
            pageSize: 6,
          })
          .then((res) => {
            if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
            console.log(res);
            this.pages = res.data.pages;
            res.data.data.forEach((order) => {
              this.$set(order, "logisticsInfo", false);

              // order.orderCommodityListItems.forEach((goods) => {
              if (order.attributeName)
                order.attributeName = order.attributeName.split(",").join("/");
              order.filePath = order.filePath.split(",")[0].endsWith(".mp4")
                ? order.filePath.split(",")[1]
                : order.filePath.split(",")[0];
              if (order.waybillNumber) order.logisticsInfo = true;
              // });
              this.orderList.push(order);
            });
            this.pageNum++;
            this.loading = false;
            this.isLoading = false;
            // 加载状态结束
            if (this.orderList.length == 0) {
              this.finished = true;
            }
            // 数据全部加载完成
            if (this.pageNum > this.pages) {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.error = true;
            this.loading = false;
            this.isLoading = false;
          });
      }
    },
    cancellationOrder(order) {
      Dialog.confirm({
        title: "取消订单",
        message: "确定取消订单？",
      })
        .then(async () => {
          let integral = 0;
          let bankIntegral = 0;
          order.orderCommodityListItems.forEach((goods) => {
            integral += goods.integral;
            bankIntegral += goods.bankIntegral;
          });

          const data = await this.$API.order.CancelOrder({
            object: {
              // totalDealNumber: dealNumber,
              dealNumber: order.dealNumber,
            },
          });
          if (integral || bankIntegral)
            await this.$store.dispatch("user/getCusBankFilePathList");
          if (integral) {
            await this.$API.user.AdjustmentIntegral({
              object: {
                availablePoints: this.bank.find((item) => {
                  return item.bankId === "0";
                }).integral,
                bankId: "0",
                integral: Math.round(integral * 100),
                operatorName: this.userInfo.userEntity.actualName
                  ? this.userInfo.userEntity.actualName
                  : "",
                operatorUserId: this.userInfo.id,
                remarks: order.dealNumber,
                type: 3,
                userId: this.userInfo.id,
              },
            });
          }
          if (bankIntegral) {
            await this.$API.user.AdjustmentIntegral({
              object: {
                availablePoints: this.bank.find((item) => {
                  return item.bankId !== "0";
                }).integral,
                bankId: this.bank.find((item) => {
                  return item.bankId !== "0";
                }).bankId,
                integral: Math.round(bankIntegral * 100),
                operatorName: this.userInfo.userEntity.actualName
                  ? this.userInfo.userEntity.actualName
                  : "",
                operatorUserId: this.userInfo.id,
                remarks: order.dealNumber,
                type: 3,
                userId: this.userInfo.id,
              },
            });
          }
          if (integral || bankIntegral)
            await this.$store.dispatch("user/getCusBankFilePathList");
          this.refresh = true;
          this.onLoad();
        })
        .catch(() => {});
    },
    deleteOrder(dealNumber) {
      Dialog.confirm({
        title: "删除订单",
        message: "确定删除订单？",
      })
        .then(async () => {
          const data = await this.$API.order.DeleteOrder({
            object: {
              // totalDealNumber: dealNumber,
              dealNumber: dealNumber,
            },
          });
          this.refresh = true;
          this.onLoad();
        })
        .catch(() => {});
    },
    confirmOrder(dealNumber) {
      Dialog.confirm({
        title: "确认收到货了吗?",
        message: "为了保证您的售后权益，请收到商品检查无误后再确认收货",
      })
        .then(async () => {
          if (this.$route.name === "my-order") {
            await this.$API.order.UpdateDealStatus({
              object: {
                dealNumber: dealNumber,
                dealStatus: 4,
              },
            });
          } else {
            await this.$API.order.UpdateIntegralDealStatus({
              object: {
                dealNumber: dealNumber,
                dealStatus: 4,
              },
            });
          }
          this.refresh = true;
          this.onLoad();
        })
        .catch(() => {});
    },
    goLogistics(order) {
      let code = [];

      order.orderCommodityListItems.forEach((goods) => {
        if (goods.waybillNumber)
          code.push({
            filePath: goods.filePath,
            waybillNumber: goods.waybillNumber,
            shipperCode: goods.shipperCode,
            shipper: goods.shipper,
          });
      });

      if (code.length) {
        sessionStorage.setItem("waybillInfo", JSON.stringify(code));
        this.$router.push({
          path: "myOrder/logisticsInformation",
        });
      } else {
        this.Toast.fail("暂无物流信息");
      }
    },
    search_btn() {
      this.refresh = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.my_order {
  background-color: #f5f5f5;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  // overflow: hidden;
  ::v-deep .van-search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 15px;
    z-index: 100;

    .van-search__content {
      height: 30px;
      background-color: #f2f2f2;
      .van-cell {
        padding: 0;
        align-items: center;
        .van-field__left-icon {
          margin-right: 8px;
          .svg-icon {
            color: #333333;
          }
        }
        .van-cell__value {
          .van-field__right-icon {
            position: absolute;
            right: 0;
            margin: 0;
            padding: 0;
            button {
              border: none;
              padding: 3px 16px;
              border-radius: 16px;
              top: 0;
              z-index: 9999;
              background: linear-gradient(180deg, #0ae377 0%, #009a4d 100%);
              color: #fff;
            }
          }
        }
        .van-field__control {
          font-size: 12px;
          padding-right: 70px;
        }
        .van-field__control {
          font-size: 12px;
        }
        .van-field__control::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #999999;
        }
        .van-field__control:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #999999;
        }
        .van-field__control::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #999999;
        }
        .van-field__control:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #999999;
        }
      }
    }
  }
  ::v-deep .van-tabs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .van-tabs__wrap {
      height: 48px;
      .van-tabs__nav {
        padding-bottom: 6px;
        .van-tab {
          font-size: 16px;
          color: #666;
        }
        .van-tab--active {
          color: #009a4d;
        }
      }
    }
    .van-tabs__content {
      position: absolute;
      top: 48px;
      bottom: 0;
      left: 0;
      right: 0;
      // max-width: 375px;
      margin: 0 auto;
      overflow-y: scroll;
      padding: 12px 15px 0;
      .van-tab__pane {
        height: 100%;
      }
    }

    .goods_box {
      background-color: #fff;
      padding: 12px;
      border-radius: 5px;
      margin-bottom: 12px;
      .goods_list {
        .store {
          display: flex;
          align-items: center;
          position: relative;
          b {
            color: #333333;
          }
          .svg_store {
            font-size: 12px;
            margin-right: 6px;
          }
          .svg_more {
            color: #999999;
            font-size: 10px;
            margin-left: 2px;
          }
          p {
            position: absolute;
            right: 0;
          }
          .dealStatus0 {
            font-size: 16px;
            color: #fb4e10;
          }
        }
        .box {
          max-height: 248px;
          overflow: hidden;
          transition: all 0.3s;
          .goods {
            display: flex;
            align-items: center;
            padding: 12px 0;
            img {
              width: 100px;
              height: 100px;
              border-radius: 5px;
              margin-right: 12px;
            }
            .details {
              position: relative;
              flex: 1;
              height: 100px;
              overflow: hidden;
              h4 {
                line-height: 22px;
                color: #333333;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                span {
                  color: #fb4e10;
                  font-weight: normal;
                }
              }
              p {
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 12px;
                color: #999999;
                span {
                  color: #333333;
                }
              }
              .attribute {
                margin: 2px 0;
                display: inline-block;
                background: #f1f1f1;
                color: #878787;
                padding: 0 10px;
                line-height: 20px;
                border-radius: 10px;
              }
              .price {
                position: absolute;
                bottom: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .integralPrice {
                  font-size: 16px;
                  font-weight: bold;
                  color: #fb4e10;
                  span {
                    color: #fb4e10;
                    font-size: 12px;
                    font-weight: normal;
                  }
                }
                span {
                  color: #333333;
                  font-size: 13px;
                  span {
                    font-size: 12px;
                    font-weight: normal;
                    display: inline-block;
                    transform: scale(0.8);
                    -webkit-transform: scale(0.8);
                    -moz-transform: scaleY(0.8);
                    position: relative;
                    left: 1px;
                    top: 1px;
                  }
                }
              }
            }
          }
        }
        .message {
          padding-top: 12px;
          overflow: hidden;
          div:nth-child(1) {
            display: flex;
            font-size: 12px;
            color: #666;
            align-items: center;
            .order_num {
              flex: 60%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding-right: 6px;
              span {
                color: #333;
              }
            }
            .time {
              flex: 40%;
              text-align: end;
            }
          }
          h6 {
            text-align: end;
            font-size: 16px;
            color: #333333;
            margin-top: 8px;
          }
          .integralPrice {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            p {
              font-size: 18px;
              color: #fb4e10;
            }
            span {
              font-size: 12px;
              font-weight: normal;
              margin-left: 4px;
              color: #fb4e10;
            }
          }
          .button {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            .Btn .van-button__text {
              color: #009a4d;
            }
            .van-button {
              padding: 0 12px;
              height: 36px;
              margin-left: 12px;
            }
            .van-button:first-child {
              margin: 0;
            }
          }
        }
        .more {
          padding-top: 16px;
          text-align: center;
          span {
            color: #333333;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .svg-icon {
            transition: 0.3s all;
            font-size: 10px;
            margin-left: 4px;
          }
        }
      }
    }
  }
  ::v-deep .van-pull-refresh {
    min-height: 100%;
    .van-pull-refresh__head {
      top: -6px;
    }
  }
}
</style>
